






























































































































































































































import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";
import moment from "moment";
import makeSelectOptions from "@common/util/makeSelectOptions";
import userHts from "@/constants/userHts";
import orderStatuses from "@/constants/orderStatuses";
import orderShippingStatuses from "@/constants/orderShippingStatuses";
import { displayItemsWithName, displayItemsWithSku } from "@/utils/marketItemDisplay";
import PasswordProtected from "./PasswordProtected.vue";
import orderPickUpStatuses from "@/constants/orderPickUpStatuses";
import XInput from "@common/modules/vuetifyx/components/XInput.vue";
import { encode, decode } from "@common/util/base64Util";
import shopWalletTransactionOriginTypes from "@/constants/shopWalletTransactionOriginTypes";

export default Vue.extend({
  components: { PasswordProtected, XInput },
  props: [
    "contentId",
    "label",
    "refreshMakePromisesFunc",
    "exportMakeUrlFunc",
    "timeKey",
    "exportHidden",
    "useProductAndComboCols",
    "isNotOrderStatistics",
    "disableTimeFilter",
    "disableStartEndTimeFilter",
    "initMonth",
    "passwordProtected",
    "orderFields",
    "isAdsStatistics",
    "isPaymentStatistics",
    "autoRefreshDisabled",
    "fields",
    "urlEnabled",
    "disableToolbar",
    "passwordProtectedScenario",
    "extraFilters",
  ],
  data() {
    return {
      self: this,
      userManager,
      data: {},
      refreshed: false,
      filtersVersion: "",
      currentStatisticOptionsHash: null,
      urlEnabledValue: this.urlEnabled === false ? false : true,
      advancedFilterOpen: false,
      refreshing: true,
      products: null,
      productCombos: null,
      market: null,
      markets: null,
      statisticType: this.$route.params.statisticType,
      tmdt: this.$route.params.statisticType === "tmdt",
      wholesale: this.$route.params.statisticType === "wholesale",
      orderBy: "",
      orderType: "desc",
      willBeDisplayedFields: {},
      displaySettingShown: false,
      filters: {
        month: null,
        startDate: null,
        endDate: null,
        dxkStartDate: null,
        dxkEndDate: null,
        productGroupIds: null,
        productIds: null,
        productComboIds: null,
        marketIds: null,
        countryIds: null,
        mktPlatformIds: null,
        departmentIds: null,
        teamIds: null,
        mktUserIds: null,
        rdUserIds: null,
        shippingCompanyIds: null,
        shippingCompanyAccountIds: null,
        warehouseIds: null,
        mktUserHts: [],
        orderStatuses: [],
        orderShippingStatuses: [],
        orderPickUpStatuses: [],
        shopIds: [],
        xShopIds: [],
        orderId: "",
        customerCode: "",
        adsAgencyIds: [],
        adsAgencyAccountIds: [],
        adsCardIds: [],
        paymentPartnerIds: [],
        paymentAccountIds: [],
        paymentOriginTypes: [],
        extraFilters: this.extraFilters || {},
      },
      filterLoaded: false,
      contentData: {},
      orderByOptions: {
        type: "select",
        attrs: {
          label: "Sắp xếp theo",
          items: this.orderFields,
        },
      },
      orderTypeOptions: {
        type: "select",
        attrs: {
          label: "Thứ tự sắp xếp",
          items: [
            { value: "desc", text: "Giảm dần" },
            { value: "asc", text: "Tăng dần" },
          ],
        },
        ext: {
          defaultValue: "desc",
        },
      },
      monthPickerOptions: {
        attrs: {
          label: "Chọn tháng",
        },
        content: {
          dpAttrs: {
            type: "month",
          },
          format: "YYYY-MM",
          setDefaultValue: true,
        },
      },
      startDatePickerOptions: {
        attrs: {
          label: "Chọn ngày bắt đầu",
        },
        content: {
          format: "YYYY-MM-DD",
        },
      },
      endDatePickerOptions: {
        attrs: {
          label: "Chọn ngày kết thúc",
        },
        content: {
          format: "YYYY-MM-DD",
        },
      },
      dxkStartDatePickerOptions: {
        type: "XDateTimePicker",
        attrs: {
          label: "Ngày xuất kho bắt đầu",
        },
        content: {
          format: "YYYY-MM-DD",
        },
      },
      dxkEndDatePickerOptions: {
        type: "XDateTimePicker",
        attrs: {
          label: "Ngày xuất kho kết thúc",
        },
        content: {
          format: "YYYY-MM-DD",
        },
      },
      productGroupsPickerOptions: {
        attrs: {
          label: "Nhóm sản phẩm",
          "item-value": "_id",
          "item-text": "sku",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            if (this.market) {
              filters.push({
                key: "countryId",
                operator: "equal_to",
                value: this.market.countryId,
              });
            }
            const { items } = await coreApiClient.call("productGroups", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithSku(items, this.market);
          },
        },
      },
      productPickersOptions: {
        attrs: {
          label: "Sản phẩm",
          "item-value": "_id",
          "item-text": "sku",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit, self) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const marketId = self.context().$route.params.marketId;
            if (self.market) {
              filters.push({
                key: "countryId",
                operator: "equal_to",
                value: this.market.countryId,
              });
            }
            const { items } = await coreApiClient.call("products", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithSku(items, marketId);
          },
        },
      },
      productCombosPickerOptions: {
        attrs: {
          label: "Combo",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit, self) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const marketId = self.context().$route.params.marketId;
            if (marketId) {
              filters.push({
                key: "marketId",
                operator: "equal_to",
                value: marketId,
              });
            }
            const { items } = await coreApiClient.call("productCombos", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithName(items, marketId);
          },
        },
      },
      marketsPickerOptions: {
        attrs: {
          label: "Thị trường",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "in_id",
                value,
              },
            ];
            const { items } = await coreApiClient.call("markets", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      countriesPickerOptions: {
        attrs: {
          label: "Quốc gia",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "in_id",
                value,
              },
            ];
            const { items } = await coreApiClient.call("countries", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      mktPlatformsPickerOptions: {
        attrs: {
          label: "Nền tảng MKT",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("mktPlatforms", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      departmentsPickerOptions: {
        attrs: {
          label: "Phòng",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "in",
                value,
              },
            ];
            const { items } = await coreApiClient.call("departments", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      teamsPickerOptions: {
        attrs: {
          label: "Team",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "in",
                value,
              },
            ];
            const { items } = await coreApiClient.call("teams", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      mktUsersPickerOptions: {
        attrs: {
          label: "Mkt User",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
              {
                key: "roles",
                operator: "equal_to",
                value: "mkt",
              },
            ];
            const { items } = await coreApiClient.call("users", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      rdUsersPickerOptions: {
        attrs: {
          label: "RD User",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
              {
                key: "roles",
                operator: "in",
                value: ["rd", "rdl"],
              },
            ];
            const { items } = await coreApiClient.call("users", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      shippingCompaniesPickerOptions: {
        attrs: {
          label: "ĐVVC",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("shippingCompanies", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      shippingCompanyAccountsPickerOptions: {
        attrs: {
          label: "TK ĐVVC",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("shippingCompanyAccounts", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      warehousesPickerOptions: {
        attrs: {
          label: "Kho",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            if (this.market) {
              filters.push({
                key: "countryId",
                operator: "equal_to",
                value: this.market.countryId,
              });
            }
            const { items } = await coreApiClient.call("warehouses", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithName(items, this.market);
          },
        },
      },
      mktUserHtsPickerOptions: {
        type: "selectMultiple",
        attrs: {
          label: "Hình thức làm việc",
          multiple: true,
          items: makeSelectOptions(userHts),
        },
      },
      orderStatusesPickerOptions: {
        type: "selectMultiple",
        attrs: {
          label: "Trạng thái đơn hàng",
          multiple: true,
          items: makeSelectOptions(orderStatuses),
        },
      },
      orderShippingStatusesPickerOptions: {
        type: "selectMultiple",
        attrs: {
          label: "Trạng thái giao hàng",
          multiple: true,
          items: [
            {
              text: "Trống",
              value: null,
            },
          ].concat(makeSelectOptions(orderShippingStatuses)),
        },
      },
      orderPickUpStatusesPickerOptions: {
        type: "selectMultiple",
        attrs: {
          label: "Trạng thái Pick up",
          multiple: true,
          items: makeSelectOptions(orderPickUpStatuses),
        },
      },
      shopIdsPickerOptions: {
        type: "textMultiple",
        attrs: {
          label: "Shop",
        },
      },
      shopsPickerOptions: {
        attrs: {
          label: "Shop",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit, self) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const marketId = self.context().$route.params.marketId;
            if (marketId) {
              filters.push({
                key: "marketId",
                operator: "equal_to",
                value: marketId,
              });
            }
            const { items } = await coreApiClient.call("shops", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return displayItemsWithName(items, marketId);
          },
        },
      },
      orderIdOptions: {
        type: "text",
        attrs: {
          label: "Order ID",
        },
      },
      customerCodeOptions: {
        type: "text",
        attrs: {
          label: "Mã KH",
        },
      },
      adsAgencyIdsPickerOptions: {
        attrs: {
          label: "Agencies",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("adsAgencies", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      adsAgencyAccountsPickerOptions: {
        attrs: {
          label: "TKQC",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit, self) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const adsAgencyId = self.context().$route.params.adsAgencyId;
            if (adsAgencyId) {
              filters.push({
                key: "adsAgencyId",
                operator: "equal_to",
                value: adsAgencyId,
              });
            }
            const { items } = await coreApiClient.call("adsAgencyAccounts", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      adsCardsPickerOptions: {
        attrs: {
          label: "Thẻ",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("adsCards", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      paymentPartnersPickerOptions: {
        attrs: {
          label: "Đối tác",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("paymentPartners", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            return items;
          },
        },
      },
      paymentAccountsPickerOptions: {
        attrs: {
          label: "TKNH",
          "item-value": "_id",
          "item-text": "name",
          "item-disabled": "_",
          multiple: true,
        },
        content: {
          async itemsSuggestor(search, value, limit) {
            const filters = [
              {
                key: "_id",
                operator: "equal_to",
                value,
              },
            ];
            const { items } = await coreApiClient.call("paymentAccounts", "findAll", {
              payload: JSON.stringify({
                limit,
                search,
                filters,
              }),
            });
            items.forEach((item) => {
              item.name = `${item.name} - ${item.cardNumber} - ${item.bankName}`;
            });
            return items;
          },
        },
      },
      paymentOriginTypesPickerOptions: {
        type: "selectMultiple",
        attrs: {
          label: "Type",
          items: makeSelectOptions(shopWalletTransactionOriginTypes),
        },
      },
      productAndComboCols: null,
    };
  },
  watch: {
    advancedFilterOpen(value) {
      if (value) {
        this.filterLoaded = true;
      }
      this.$emit("contentResized");
    },
    $route() {
      if (this.urlEnabledValue) {
        this.restoreFromUrl();
        this.refreshData();
      }
    },
  },
  computed: {
    days() {
      const startDay = this.startDay();
      const endDay = this.endDay();
      const format = "YYYY-MM-DD";
      const items = Array.from({ length: endDay.diff(startDay, "days") + 1 }, (x, i) => startDay.clone().add(i, "days").format(format));
      return items;
    },
    months() {
      const startMonth = moment(this.filters.month).startOf("year");
      const items = Array.from({ length: 12 }, (x, i) => startMonth.clone().add(i, "months").format("YYYY-MM"));
      return items;
    },
    scenario() {
      if (this.passwordProtected) {
        return this.passwordProtectedScenario || "viewProtectedPage";
      }
      return "";
    },
  },
  methods: {
    async refresh() {
      if (this.urlEnabledValue) {
        if (!this.saveToUrl()) {
          await this.refreshData();
        }
      } else {
        await this.refreshData();
      }
    },
    async refreshData() {
      this.$set(this.contentData, "statisticType", this.statisticType);
      this.$set(this.contentData, "tmdt", this.tmdt);
      this.$set(this.contentData, "wholesale", this.wholesale);
      this.$set(this.contentData, "days", [...this.days]);
      this.$set(this.contentData, "months", [...this.months]);
      this.$set(this.contentData, "orderBy", this.orderBy);
      this.$set(this.contentData, "orderType", this.orderType);
      this.$set(this.contentData, "willBeDisplayedFields", this.willBeDisplayedFields);
      this.$set(this.contentData, "startDay", this.startDay().valueOf());
      this.$set(this.contentData, "endDay", this.endDay().valueOf());
      for (const k in this.filters) {
        this.$set(this.contentData, k, JSON.parse(JSON.stringify(this.filters[k])));
      }
      this.refreshing = true;
      if (this.statisticType && !this.markets) {
        const { items } = await coreApiClient.call("markets", "findAll", {
          payload: JSON.stringify({
            limit: -1,
            filters: [
              this.statisticType && {
                key: "tmdt",
                operator: this.tmdt ? "equal_to" : "not_equal_to",
                value: true,
              },
              this.statisticType && {
                key: "wholesale",
                operator: this.wholesale ? "equal_to" : "not_equal_to",
                value: true,
              },
            ],
          }),
        });
        this.markets = items.filter((it) => !it.code.includes("telesale"));
      }
      const startDate = this.startDay().valueOf();
      const endDate = this.endDay().valueOf();
      const timeKey = this.timeKey || "createdTime";
      const baseFilters: any = [
        {
          key: timeKey,
          operator: "greater_than_or_equal_to",
          value: startDate,
          id: "startDate",
        },
        {
          key: timeKey,
          operator: "less_than_or_equal_to",
          value: endDate,
          id: "endDate",
        },
      ];
      if (this.$route.params.marketId) {
        baseFilters.push({
          key: "marketId",
          operator: "equal_to",
          value: this.$route.params.marketId,
        });
      }
      baseFilters.push({
        key: "tiktokIsSampleRequest",
        operator: "not_equal_to",
        value: true,
        id: "tiktokIsSampleRequest",
      });
      if (this.filters.dxkStartDate) {
        baseFilters.push({
          key: "dxkTime",
          operator: "greater_than_or_equal_to",
          value: moment(this.filters.dxkStartDate).startOf("day").valueOf(),
          id: "dxkStartDate",
        });
      }
      if (this.filters.dxkEndDate) {
        baseFilters.push({
          key: "dxkTime",
          operator: "less_than_or_equal_to",
          value: moment(this.filters.dxkEndDate).endOf("day").valueOf(),
          id: "dxkEndDate",
        });
      }
      const orderProductFilters = [];
      if (this.filters.productGroupIds && this.filters.productGroupIds.length) {
        orderProductFilters.push({
          key: "mainProductGroupIds",
          operator: "in",
          value: this.filters.productGroupIds,
        });
        if (this.tmdt) {
          orderProductFilters.push({
            key: "productItems.product.productGroupId",
            operator: "in",
            value: this.filters.productGroupIds,
          });
        }
      }
      if (this.filters.productIds && this.filters.productIds.length) {
        orderProductFilters.push({
          key: "mainProductItems.product._id",
          operator: "in_id",
          value: this.filters.productIds,
        });
      }
      if (this.filters.productComboIds && this.filters.productComboIds.length) {
        orderProductFilters.push({
          key: "productCombos._id",
          operator: "in_id",
          value: this.filters.productComboIds,
        });
      }
      if (this.filters.marketIds && this.filters.marketIds.length) {
        orderProductFilters.push({
          key: "marketId",
          operator: "in",
          value: this.filters.marketIds,
        });
      }
      if (this.filters.countryIds && this.filters.countryIds.length) {
        orderProductFilters.push({
          key: "countryId",
          operator: "in",
          value: this.filters.countryIds,
        });
      }
      if (this.filters.mktPlatformIds && this.filters.mktPlatformIds.length) {
        orderProductFilters.push({
          key: "mktPlatformId",
          operator: "in",
          value: this.filters.mktPlatformIds,
        });
      }
      if (this.filters.departmentIds && this.filters.departmentIds.length) {
        orderProductFilters.push({
          key: "mktDepartmentId",
          operator: "in",
          value: this.filters.departmentIds,
        });
      }
      if (this.filters.teamIds && this.filters.teamIds.length) {
        orderProductFilters.push({
          key: "mktTeamId",
          operator: "in",
          value: this.filters.teamIds,
        });
      }
      if (this.filters.mktUserIds && this.filters.mktUserIds.length) {
        orderProductFilters.push({
          key: "mktUserId",
          operator: "in",
          value: this.filters.mktUserIds,
        });
      }
      if (this.filters.rdUserIds && this.filters.rdUserIds.length) {
        orderProductFilters.push({
          key: "rdUserId",
          operator: "in",
          value: this.filters.rdUserIds,
        });
      }
      if (this.filters.shippingCompanyIds && this.filters.shippingCompanyIds.length) {
        orderProductFilters.push({
          key: "shippingCompanyId",
          operator: "in",
          value: this.filters.shippingCompanyIds,
        });
      }
      if (this.filters.shippingCompanyAccountIds && this.filters.shippingCompanyAccountIds.length) {
        orderProductFilters.push({
          key: "shippingCompanyAccountId",
          operator: "in",
          value: this.filters.shippingCompanyAccountIds,
        });
      }
      if (this.filters.warehouseIds && this.filters.warehouseIds.length) {
        orderProductFilters.push({
          key: "warehouseId",
          operator: "in",
          value: this.filters.warehouseIds,
        });
      }
      if (this.filters.mktUserHts && this.filters.mktUserHts.length) {
        orderProductFilters.push({
          key: "mktUserHt",
          operator: "in",
          value: this.filters.mktUserHts,
        });
      }
      if (this.filters.orderStatuses && this.filters.orderStatuses.length) {
        orderProductFilters.push({
          key: "status",
          operator: "in",
          value: this.filters.orderStatuses,
        });
      }
      if (this.filters.orderShippingStatuses && this.filters.orderShippingStatuses.length) {
        orderProductFilters.push({
          key: "shippingStatus",
          operator: "in",
          value: this.filters.orderShippingStatuses,
        });
      }
      if (this.filters.orderPickUpStatuses && this.filters.orderPickUpStatuses.length) {
        orderProductFilters.push({
          key: "pickUpStatus",
          operator: "in",
          value: this.filters.orderPickUpStatuses,
        });
      }
      if (this.filters.shopIds && this.filters.shopIds.length) {
        orderProductFilters.push({
          key: "shopId",
          operator: "in",
          value: this.filters.shopIds,
        });
      }
      if (this.filters.xShopIds && this.filters.xShopIds.length) {
        orderProductFilters.push({
          key: "xShopId",
          operator: "in",
          value: this.filters.xShopIds,
        });
      }
      if (this.filters.orderId) {
        orderProductFilters.push({
          key: "orderId",
          operator: "equal_to",
          value: this.filters.orderId,
        });
      }
      if (this.filters.customerCode) {
        orderProductFilters.push({
          key: "customerCode",
          operator: "equal_to",
          value: this.filters.customerCode,
        });
      }
      if (this.statisticType) {
        orderProductFilters.push({
          key: "marketId",
          operator: "in",
          value: this.markets.map((item) => item._id),
        });
      }
      const orderFilters = [...baseFilters].concat(orderProductFilters);

      const mktCostFilters: any = [
        {
          key: "time",
          operator: "greater_than_or_equal_to",
          value: startDate,
          id: "startDate",
        },
        {
          key: "time",
          operator: "less_than_or_equal_to",
          value: endDate,
          id: "endDate",
        },
      ];
      if (this.$route.params.marketId) {
        mktCostFilters.push({
          key: "marketId",
          operator: "equal_to",
          value: this.$route.params.marketId,
        });
      }
      if (this.filters.marketIds && this.filters.marketIds.length) {
        mktCostFilters.push({
          key: "marketId",
          operator: "in",
          value: this.filters.marketIds,
        });
      }
      if (this.filters.mktUserIds && this.filters.mktUserIds.length) {
        mktCostFilters.push({
          key: "userId",
          operator: "in",
          value: this.filters.mktUserIds,
        });
      }
      if (this.filters.teamIds && this.filters.teamIds.length) {
        mktCostFilters.push({
          key: "teamId",
          operator: "in",
          value: this.filters.teamIds,
        });
      }
      if (this.filters.departmentIds && this.filters.departmentIds.length) {
        mktCostFilters.push({
          key: "departmentId",
          operator: "in",
          value: this.filters.departmentIds,
        });
      }
      if (this.filters.mktUserHts && this.filters.mktUserHts.length) {
        mktCostFilters.push({
          key: "userHt",
          operator: "in",
          value: this.filters.mktUserHts,
        });
      }
      if (this.filters.shopIds && this.filters.shopIds.length) {
        mktCostFilters.push({
          key: "shopId",
          operator: "in",
          value: this.filters.shopIds,
        });
      }
      if (this.filters.xShopIds && this.filters.xShopIds.length) {
        mktCostFilters.push({
          key: "xShopId",
          operator: "in",
          value: this.filters.xShopIds,
        });
      }
      if (this.statisticType) {
        mktCostFilters.push({
          key: "marketId",
          operator: "in",
          value: this.markets.map((item) => item._id),
        });
      }

      const adsCostFilters: any[] = [
        {
          key: "startTime",
          operator: "greater_than_or_equal_to",
          value: startDate,
          id: "startDate",
        },
        {
          key: "endTime",
          operator: "less_than_or_equal_to",
          value: endDate,
          id: "endDate",
        },
      ];
      if (this.filters.mktUserIds && this.filters.mktUserIds.length) {
        adsCostFilters.push({
          key: "mktUserId",
          operator: "in",
          value: this.filters.mktUserIds,
        });
      }
      if (this.filters.teamIds && this.filters.teamIds.length) {
        adsCostFilters.push({
          key: "mktTeamId",
          operator: "in",
          value: this.filters.teamIds,
        });
      }
      if (this.filters.departmentIds && this.filters.departmentIds.length) {
        adsCostFilters.push({
          key: "mktDepartmentId",
          operator: "in",
          value: this.filters.departmentIds,
        });
      }
      if (this.$route.params.adsAgencyId) {
        adsCostFilters.push({
          key: "adsAgencyId",
          operator: "equal_to",
          value: this.$route.params.adsAgencyId,
        });
      }
      if (this.filters.adsAgencyIds && this.filters.adsAgencyIds.length) {
        adsCostFilters.push({
          key: "adsAgencyId",
          operator: "in",
          value: this.filters.adsAgencyIds,
        });
      }
      if (this.filters.adsAgencyAccountIds && this.filters.adsAgencyAccountIds.length) {
        adsCostFilters.push({
          key: "adsAgencyAccountId",
          operator: "in",
          value: this.filters.adsAgencyAccountIds,
        });
      }

      const adsRdCostFilters: any[] = [
        {
          key: "time",
          operator: "greater_than_or_equal_to",
          value: startDate,
          id: "startDate",
        },
        {
          key: "time",
          operator: "less_than_or_equal_to",
          value: endDate,
          id: "endDate",
        },
      ];
      if (this.filters.mktUserIds && this.filters.mktUserIds.length) {
        adsRdCostFilters.push({
          key: "mktUserId",
          operator: "in",
          value: this.filters.mktUserIds,
        });
      }
      if (this.filters.teamIds && this.filters.teamIds.length) {
        adsRdCostFilters.push({
          key: "mktTeamId",
          operator: "in",
          value: this.filters.teamIds,
        });
      }
      if (this.filters.departmentIds && this.filters.departmentIds.length) {
        adsRdCostFilters.push({
          key: "mktDepartmentId",
          operator: "in",
          value: this.filters.departmentIds,
        });
      }
      if (this.$route.params.adsAgencyId) {
        adsRdCostFilters.push({
          key: "adsAgencyId",
          operator: "equal_to",
          value: this.$route.params.adsAgencyId,
        });
      }
      if (this.filters.adsAgencyIds && this.filters.adsAgencyIds.length) {
        adsRdCostFilters.push({
          key: "adsAgencyId",
          operator: "in",
          value: this.filters.adsAgencyIds,
        });
      }
      if (this.filters.adsAgencyAccountIds && this.filters.adsAgencyAccountIds.length) {
        adsRdCostFilters.push({
          key: "adsAgencyAccountId",
          operator: "in",
          value: this.filters.adsAgencyAccountIds,
        });
      }

      const adsBillFilters: any[] = [
        {
          key: "time",
          operator: "greater_than_or_equal_to",
          value: startDate,
          id: "startDate",
        },
        {
          key: "time",
          operator: "less_than_or_equal_to",
          value: endDate,
          id: "endDate",
        },
        {
          key: "adsCardId",
          operator: "not_equal_to",
          value: null,
        },
      ];
      if (this.filters.mktUserIds && this.filters.mktUserIds.length) {
        adsBillFilters.push({
          key: "mktUserId",
          operator: "in",
          value: this.filters.mktUserIds,
        });
      }
      if (this.filters.teamIds && this.filters.teamIds.length) {
        adsBillFilters.push({
          key: "mktTeamId",
          operator: "in",
          value: this.filters.teamIds,
        });
      }
      if (this.filters.departmentIds && this.filters.departmentIds.length) {
        adsBillFilters.push({
          key: "mktDepartmentId",
          operator: "in",
          value: this.filters.departmentIds,
        });
      }
      if (this.$route.params.adsAgencyId) {
        adsBillFilters.push({
          key: "adsAgencyId",
          operator: "equal_to",
          value: this.$route.params.adsAgencyId,
        });
      }
      if (this.filters.adsAgencyIds && this.filters.adsAgencyIds.length) {
        adsBillFilters.push({
          key: "adsAgencyId",
          operator: "in",
          value: this.filters.adsAgencyIds,
        });
      }
      if (this.filters.adsAgencyAccountIds && this.filters.adsAgencyAccountIds.length) {
        adsBillFilters.push({
          key: "adsAgencyAccountId",
          operator: "in",
          value: this.filters.adsAgencyAccountIds,
        });
      }

      const adsAgencyBalanceChangeFilters: any[] = [
        {
          key: "time",
          operator: "greater_than_or_equal_to",
          value: startDate,
          id: "startDate",
        },
        {
          key: "time",
          operator: "less_than_or_equal_to",
          value: endDate,
          id: "endDate",
        },
      ];
      if (this.$route.params.adsAgencyId) {
        adsAgencyBalanceChangeFilters.push({
          key: "adsAgencyId",
          operator: "equal_to",
          value: this.$route.params.adsAgencyId,
        });
      }
      if (this.filters.adsAgencyIds && this.filters.adsAgencyIds.length) {
        adsAgencyBalanceChangeFilters.push({
          key: "adsAgencyId",
          operator: "in",
          value: this.filters.adsAgencyIds,
        });
      }

      const promises = this.refreshMakePromisesFunc({
        ...this.filters,
        startDate,
        endDate,
        baseFilters,
        orderFilters,
        orderProductFilters,
        mktCostFilters,
        adsCostFilters,
        adsRdCostFilters,
        adsBillFilters,
        adsAgencyBalanceChangeFilters,
        statisticType: this.statisticType,
        tmdt: this.tmdt,
        wholesale: this.wholesale,
        markets: this.markets,
        orderBy: this.orderBy,
        orderType: this.orderType,
        exportBaseQueries: this.exportBaseQueries(),
        scenario: this.scenario,
      });

      const propertyList = [];
      const promiseList = [];
      for (const k in promises) {
        propertyList.push(k);
        promiseList.push(promises[k]);
      }
      const arr = await Promise.all(promiseList);
      for (const i in arr) {
        const property = propertyList[i];
        const value = arr[i];
        this.data[property] = value;
      }

      this.refreshing = false;
      this.$emit("refreshed", this.data, this.filters, this);
      this.refreshed = true;
    },
    exportBaseQueries() {
      const baseQueries: any = {
        ...this.filters,
      };
      baseQueries.extraFilters = JSON.stringify(this.filters.extraFilters);
      if (this.$route.params.marketId) {
        baseQueries.marketId = this.$route.params.marketId;
      }
      if (this.$route.params.adsAgencyId) {
        baseQueries.adsAgencyId = this.$route.params.adsAgencyId;
      }
      if (this.statisticType) {
        baseQueries.statisticType = this.statisticType;
      }
      baseQueries.orderBy = this.orderBy;
      baseQueries.orderType = this.orderType;
      baseQueries.willBeDisplayedFields = this.willBeDisplayedFields;
      return baseQueries;
    },
    exportExcel() {
      const { path, queries } = this.exportMakeUrlFunc(this.exportBaseQueries());
      coreApiClient.download(
        "statistics",
        `@/${path}`,
        {
          payload: JSON.stringify(queries),
        },
        this.scenario
      );
    },
    resetFilters() {
      for (const filter in this.filters) {
        this.filters[filter] = null;
      }
      this.filters.month = moment().valueOf();
    },
    startDay() {
      if (this.filters.startDate) {
        return moment(this.filters.startDate).startOf("day");
      }
      return moment(this.filters.month).startOf("month").startOf("day");
    },
    endDay() {
      if (this.filters.endDate) {
        return moment(this.filters.endDate).endOf("day");
      }
      return moment(this.filters.month).endOf("month").endOf("day");
    },
    displaySettingKey() {
      let id = this.contentId || this.$route.path;
      if (!id) {
        const matched = this.$route.matched;
        id = (matched && matched.length && matched[matched.length - 1] && (matched[matched.length - 1] as any).path) || this.$route.path;
      }
      return `statisticsBase-${id}`;
    },
    saveDisplaySetting() {
      localStorage.setItem(this.displaySettingKey(), JSON.stringify(this.willBeDisplayedFields));
      this.refresh();
    },
    restoreDisplaySetting() {
      const valueData = localStorage.getItem(this.displaySettingKey());
      if (!valueData) {
        for (const field in this.fields) {
          this.willBeDisplayedFields[field] = true;
        }
      } else {
        this.willBeDisplayedFields = JSON.parse(valueData);
      }
    },
    getStatisticOptionsHash() {
      const statisticOptions: any = {
        filters: this.filters,
        orderBy: this.orderBy,
        orderType: this.orderType,
      };
      return encode(JSON.stringify(statisticOptions));
    },
    saveToUrl() {
      this.currentStatisticOptionsHash = this.getStatisticOptionsHash();
      if (this.currentStatisticOptionsHash !== this.$route.query.statistic_options) {
        const query = (this.$route.query && { ...this.$route.query }) || {};
        query.statistic_options = this.currentStatisticOptionsHash;
        this.$router
          .push({
            path: this.$route.path,
            query,
          })
          .catch(() => {});
        return true;
      }
      return false;
    },
    restoreFromUrl() {
      const query = this.$route.query;
      if (query.statistic_options) {
        const statisticOptions = JSON.parse(decode(query.statistic_options));
        if (statisticOptions.filters) {
          for (const k in statisticOptions.filters) {
            this.$set(this.filters, k, statisticOptions.filters[k]);
          }
        }
        if (statisticOptions.orderBy) {
          this.orderBy = statisticOptions.orderBy;
        }
        if (statisticOptions.orderType) {
          this.orderType = statisticOptions.orderType;
        }
      }
      this.filtersVersion = JSON.stringify(this.filters);
    },
  },
  async created() {
    if (this.initMonth) {
      this.filters.month = this.initMonth;
    } else {
      this.filters.month = moment().valueOf();
    }
    if (this.$route.params.marketId) {
      const market = await coreApiClient.call("markets", "findById", {
        id: this.$route.params.marketId,
      });
      if (market) {
        this.tmdt = market.tmdt;
        this.wholesale = market.wholesale;
      }
      this.market = market;
    }
    if (this.useProductAndComboCols) {
      {
        const filters = [];
        if (this.market) {
          filters.push({
            key: "countryId",
            operator: "equal_to",
            value: this.market.countryId,
          });
        }
        const { items } = await coreApiClient.call("products", "findAll", {
          payload: JSON.stringify({
            limit: -1,
            filters,
            includedProperties: ["_id", "sku", "name", "productGroupId"],
          }),
        });
        this.products = displayItemsWithSku(items, this.$route.params.marketId);
      }
      {
        const filters = [];
        if (this.$route.params.marketId) {
          filters.push({
            key: "marketId",
            operator: "equal_to",
            value: this.$route.params.marketId,
          });
        }
        const { items } = await coreApiClient.call("productCombos", "findAllWithoutMktPlatformFilters", {
          payload: JSON.stringify({
            limit: -1,
            filters,
            includedProperties: ["_id", "sku", "name", "marketId", "market", "productItems"],
          }),
        });
        this.productCombos = displayItemsWithName(items, this.$route.params.marketId);
      }
      {
        const getProductGroupId = (col) => {
          if (col.type === "product") {
            return col.item.productGroupId || "";
          }
          let productItem;
          if (col.item.productItems) {
            productItem = col.item.productItems.find((pi) => pi.role === "main" && pi.belongsToProductGroupId);
          }
          if (productItem) {
            productItem.belongsToProductGroupId;
          }
          return "";
        };

        let cols = [];
        cols = cols.concat(
          this.products.map((item) => {
            return { type: "product", item };
          })
        );
        cols = cols.concat(
          this.productCombos.map((item) => {
            return { type: "productCombo", item };
          })
        );
        cols = cols.map((col) => {
          col.sortCandidate = getProductGroupId(col);
          return col;
        });
        cols = cols.sort((a, b) => {
          return a.sortCandidate.localeCompare(b.sortCandidate);
        });
        this.productAndComboCols = cols;
        this.$set(this.contentData, "products", this.products);
        this.$set(this.contentData, "productCombos", this.productCombos);
        this.$set(this.contentData, "productAndComboCols", this.productAndComboCols);
      }
    }
    if (this.fields) {
      this.restoreDisplaySetting();
    }

    if (this.urlEnabledValue) {
      this.restoreFromUrl();
    }

    this.refreshing = false;
    // if (!this.passwordProtected) {
    //   this.refresh();
    // }
  },
});
